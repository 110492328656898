import Helmet from "react-helmet";
import Footer from "../../components/footer";
import "./style.scss";
import NavBar from "../../components/navbar";
import { Form, notification } from "antd";
import { MaskedInput } from "antd-mask-input";
import { httClient } from "../../services/api";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>KiVenda - Contato</title>
      </Helmet>
      <NavBar />
      <div className="contact-container">
        <Hero />
        <ContactSection />
        <ContactLinks />
        <Footer />
      </div>
    </>
  );
};

const Hero = () => {
  return (
    <>
      <section className="section-global bg-shade-1 hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="section-tag mb-8">Contato</div>
              <h1 className="display-1">
                Estamos sempre aqui para garantir a satisfação do cliente{" "}
              </h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const ContactSection = () => {
  const [form] = Form.useForm();
  const features_data = [
    "Perfeito, abrangente e econômico",
    "Conecte-se com seus clientes",
    "Simplifique suas operações",
  ];

  const onFinish = async (values) => {
    try {
      await httClient({
        method: "POST",
        url: "/forms/register/landing",
        data: { ...values, origin: "landing-page" },
      });

      notification.success({
        message: "Sua mensagem foi enviada com sucesso!",
        description: "Entraremos em contato em breve",
      });

      form.resetFields();
    } catch (error) {
      console.error("Failed:", error);
    }
  };

  return (
    <>
      <section className="section-global">
        <div className="container container-2">
          <div className="row gy-5">
            <div className="col-lg-6">
              <h2 className="mb-16">
                Contate nossa equipe de vendas e suporte
              </h2>
              <p className="txt-1">
                Eliminamos a desordem para descobrir novas oportunidades e
                sempre garantimos a satisfação do cliente
              </p>
              {features_data.map((e, i) => (
                <div
                  key={i}
                  className="txt-2 color-1 fw-500 mb-8 d-flex align-items-center"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="me-2"
                  >
                    <rect width="24" height="24" rx="12" fill="#0FA958" />
                    <path
                      d="M6.66675 12L10.6667 16L17.3334 8"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  {e}
                </div>
              ))}
            </div>
            <div className="col-lg-6">
              <Form className="row g-3" onFinish={onFinish} form={form}>
                <div className="col-md-6">
                  <label className="form-label">Primeiro nome</label>
                  <Form.Item
                    name={"name"}
                    className="mb-0"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, informe seu nome",
                      },
                    ]}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="João"
                      required
                    />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Ultimo nome</label>
                  <Form.Item
                    name={"lastName"}
                    className="mb-0"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, informe seu sobrenome",
                      },
                    ]}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Brent"
                    />
                  </Form.Item>
                </div>
                <div className="col-6">
                  <label className="form-label">Email</label>
                  <Form.Item
                    name={"email"}
                    className="mb-0"
                    rules={[
                      {
                        type: "email",
                        message: "O email informado não é válido",
                      },
                      {
                        required: true,
                        message: "Por favor, informe seu email",
                      },
                    ]}
                  >
                    <input
                      type="email"
                      className="form-control"
                      placeholder="joao@email.com"
                      required
                    />
                  </Form.Item>
                </div>
                <div className="col-6">
                  <label className="form-label">Telefone</label>
                  <Form.Item
                    name={"phone"}
                    className="mb-0"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, informe seu telefone",
                      },
                    ]}
                  >
                    <MaskedInput
                      className={`form-control `}
                      mask={[{ mask: "(00) 00000-0000" }]}
                      placeholder="DDD + Telefone"
                      maskOptions={{
                        dispatch: function (_, dynamicMasked) {
                          return dynamicMasked.compiledMasks[0];
                        },
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="col-12">
                  <label className="form-label">Assunto</label>
                  <Form.Item name={"subject"} className="mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Informe o assunto"
                      required
                    />
                  </Form.Item>
                </div>
                <div className="col-md-12">
                  <label className="form-label">Descreva sua solicitação</label>
                  <Form.Item name={"message"} className="mb-0">
                    <textarea
                      className="form-control"
                      rows="4"
                      placeholder="Como posso ajudar você?"
                      required
                    ></textarea>
                  </Form.Item>
                </div>
                <div className="col-12">
                  <button type="submit" className="btn btn-primary btn-lg">
                    Enviar
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const ContactLinks = () => {
  const contactLinkData = [
    {
      title: "Email",
      text: "contato@kivenda.com",
      icoUrl: "assets/contact_ico1.svg",
      link: "mailto:contato@kivenda.com",
    },
    {
      title: "Telefone",
      text: "49 99835-7777",
      icoUrl: "assets/contact_ico2.svg",
      link: "tel:+5549998357777",
    },
    {
      title: "Avenida Fernando Machado",
      text: "141 E Ed IL Centenário Sala 107 Bairro: Centro CEP: 89802-112 Chapecó-SC",
      icoUrl: "assets/contact_ico3.svg",
      link: "/",
    },
  ];

  return (
    <>
      <section className="section-global bg-shade-blue">
        <div className="container container-2">
          <div className="row gy-4">
            {contactLinkData.map((e, i) => (
              <div className="col-lg-4 col-md-6" key={i}>
                <a href={e.link} className="contact-link">
                  <div className="contact-link-container d-flex align-items-center">
                    <img
                      src={require(`./${e.icoUrl}`)}
                      height="50"
                      alt={e.title}
                    />
                    <div className="ms-3">
                      <div className="txt-2 fw-500 color-1">{e.title}</div>
                      <div className="txt-3">{e.text}</div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
