import { Helmet } from "react-helmet";
import { Pricing, pricing_data } from "../landing1";
import "./style.scss";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Form } from "antd";
import { MaskedInput } from "antd-mask-input";
import Footer from "../../components/footer";
import { httClient } from "../../services/api";
import ConfettiExplosion from "react-confetti-explosion";

export const Subscription = () => {
  const [searchParams] = useSearchParams();
  const [querys, setQuerys] = useState({});
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const planSelected = pricing_data.find((f) => f.title === querys.plan);
  const [valuePlan, setValuePlan] = useState(0);
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    if (planSelected) {
      setValuePlan(+planSelected.price.replace("R$ ", ""));
    }
    setQuerys(Object.fromEntries([...searchParams]));
  }, [planSelected, searchParams]);

  const onFinish = async (values) => {
    try {
      setSuccess(false);
      setLoading(true);
      const { data } = await httClient({
        url: "/payment/signature",
        method: "PUT",
        data: {
          ...values,
          value: valuePlan,
          plan: querys.plan,
          user_id: querys.user,
        },
      });
      setSuccess(true);
      setLoading(false);
      if (data?.paymentLink) {
        window.open(data.paymentLink, "_blank");
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error("As senhas não conferem, por favor, tente novamente!")
      );
    },
  });

  return (
    <>
      <Helmet>
        <title>KiVenda</title>
      </Helmet>
      <div className=" container-subscription">
        {!["Basic", "Pro", "Enterprise"].includes(querys.plan) && (
          <Pricing
            description={
              "Selecione o plano que melhor atende as suas necessidades. "
            }
          />
        )}

        {["Basic", "Pro", "Enterprise"].includes(querys.plan) && (
          <>
            <section className="section-global p-5 bg-shade-1 hero">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7">
                    <h1 className="display-1">
                      Parabéns, você está quase lá!{" "}
                    </h1>
                  </div>
                </div>
              </div>
            </section>
            <section className="section-global   p-5">
              <div className="container container-2">
                <div className="row gy-5">
                  <div className="col-lg-6">
                    <h2 className="mb-16">
                      Cadastro de informações para assinatura
                    </h2>
                    <p className="txt-1">
                      Preencha o formulário abaixo para finalizar a assinatura
                      do plano <strong>{querys.plan}</strong>{" "}
                      {Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(valuePlan)}
                    </p>
                    {planSelected?.included?.map((e, i) => (
                      <div
                        key={i}
                        className="txt-2 color-1 fw-500 mb-8 d-flex align-items-center"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="me-2"
                        >
                          <rect width="24" height="24" rx="12" fill="#0FA958" />
                          <path
                            d="M6.66675 12L10.6667 16L17.3334 8"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        {e}
                      </div>
                    ))}
                  </div>
                  {success ? (
                    <div className="col-lg-6 ">
                      <ConfettiExplosion />
                      <h2 className="mb-16">
                        Parabéns, sua assinatura foi realizada com sucesso!
                      </h2>
                      <p className="txt-1">
                        Acesse o seu email para confirmar a assinatura, efetuar
                        o pagamento e aproveitar todos os benefícios do plano{" "}
                      </p>
                    </div>
                  ) : (
                    <div className="col-lg-6">
                      <Form className="row g-3" form={form} onFinish={onFinish}>
                        {!querys?.user && (
                          <>
                            <div className="col-md-6">
                              <label className="form-label">
                                Primeiro nome
                              </label>
                              <Form.Item name={"name"} className="mb-0">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="João"
                                  required
                                />
                              </Form.Item>
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">Ultimo nome</label>
                              <Form.Item name={"last_name"} className="mb-0">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Brent"
                                  required
                                />
                              </Form.Item>
                            </div>

                            <div className="col-md-6">
                              <label className="form-label">Senha</label>
                              <Form.Item
                                name={"password"}
                                className="mb-0"
                                rules={[
                                  {
                                    required: true,
                                    message: "Por favor, insira sua senha!",
                                  },
                                  {
                                    min: 6,
                                    message:
                                      "A senha deve ter no mínimo 6 caracteres!",
                                  },
                                ]}
                              >
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Senha"
                                  required
                                />
                              </Form.Item>
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                Confirmar Senha
                              </label>
                              <Form.Item
                                name={"confirm_password"}
                                className="mb-0"
                                dependencies={["password"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please confirm your password!",
                                  },
                                  validateConfirmPassword,
                                ]}
                              >
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Confirmar Senha"
                                  required
                                />
                              </Form.Item>
                            </div>

                            <div className="col-12">
                              <label className="form-label">Email</label>
                              <Form.Item name={"email"} className="mb-0">
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="joao@email.com"
                                  required
                                />
                              </Form.Item>
                            </div>
                          </>
                        )}
                        <div className="col-md-6">
                          <label className="form-label">
                            Telefone para contato
                          </label>
                          <Form.Item name={"phone"} className="mb-0">
                            <MaskedInput
                              mask={[{ mask: "(00) 00000-0000" }]}
                              placeholder="Celular e DD"
                              className="form-control"
                              maskOptions={{
                                dispatch: function (_, dynamicMasked) {
                                  return dynamicMasked.compiledMasks[0];
                                },
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">Cpf/Cnpj</label>
                          <Form.Item name={"cpfCnpj"} className="mb-0">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Cpf/Cnpj apenas números"
                              required
                            />
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label">Cep</label>
                          <Form.Item name={"postalCode"} className="mb-0">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="00000-000"
                              required
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">Bairro</label>
                          <Form.Item name={"province"} className="mb-0">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Bairro"
                              required
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-12">
                          <label className="form-label">Endereço</label>
                          <Form.Item name={"address"} className="mb-0">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Rua, 123"
                              required
                            />
                          </Form.Item>
                        </div>

                        <div className="col-12 d-flex justify-content-end">
                          <button
                            loading={loading}
                            disabled={loading}
                            type="submit"
                            className="btn btn-primary btn-lg"
                          >
                            {loading && (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                            Assinar
                          </button>
                        </div>
                      </Form>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};
