import { notification } from "antd";
import axios from "axios";

export const httClient = axios.create({
  baseURL:
    process.env.REACT_APP_URL_API ||
    "https://kirango-api-production.up.railway.app",
});

httClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const notificationParam = {
      message: "",
      description: "",
    };

    // Remove token and redirect
    if (error.response.status === 401) {
      notificationParam.message = "Authentication Fail";
      notificationParam.description = "Please login again";
      // window.location.reload();
    }

    if (error.response.status === 404) {
      if (error?.response?.data?.message) {
        notificationParam.message = error?.response?.data?.message;
      } else {
        notificationParam.message = "Not Found";
      }
    }

    if (error.response.status === 500) {
      notificationParam.message = "Internal Server Error";
    }

    if (error.response.status === 508) {
      notificationParam.message = "Time Out";
    }

    if (error.response.status === 400) {
      if (error?.response?.data?.message) {
        notificationParam.message = error?.response?.data?.message;
      } else {
        notificationParam.message = "BAD_REQUEST";
      }
    }

    if (error.response.status) {
      notificationParam.message = error.response.data.message;
    }

    notification.error(notificationParam);

    return Promise.reject(error);
  }
);
