import React from "react";
import "./WhatsAppButton.css"; // CSS para estilização do botão

const WhatsAppButton = () => {
  const phoneNumber = "5549998357777"; // Substitua pelo seu número de WhatsApp
  const message =
    "Olá! Gostaria de saber mais sobre os seus serviços do KiVenda."; // Mensagem padrão

  const handleClick = () => {
    window.open(
      `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`,
      "_blank"
    );
  };

  return (
    <div className="whatsapp-button" onClick={handleClick}>
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png"
        alt="WhatsApp"
        className="whatsapp-icon"
      />
    </div>
  );
};

export default WhatsAppButton;
