import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import BlogCards from "../../components/blogCards";
import BrandSection from "../../components/brandSection";
import Footer from "../../components/footer";
import PricingCard from "../../components/pricingCard";
import ServiceCards from "../../components/serviceCards";
import "./style.scss";
import NavBar from "../../components/navbar";
import ContactSection from "../../components/contactSection";
import { anchor } from "../../utils/anchor";
import React from "react";
import { Slugify } from "../../utils/slugify";
import { httClient } from "../../services/api";
import ConfettiExplosion from "react-confetti-explosion";
import { Card, Form, Radio, Space } from "antd";
import { WhatsAppOutlined, ShopOutlined } from "@ant-design/icons";
import { MaskedInput } from "antd-mask-input";

const Landing1 = () => {
  return (
    <>
      <Helmet>
        <title>KiVenda</title>
      </Helmet>
      <NavBar />
      <div className="landing-1">
        <Hero />
        <Services />
        <About />
        {/* <Counters /> */}
        <Pricing />
        <Blog />
        <Contact />
        <Footer />
      </div>
    </>
  );
};

const Hero = () => {
  const [slug, setSlug] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [slugIsAvailable, setSlugIsAvailable] = React.useState(false);
  const [slugError, setSlugError] = React.useState(false);
  const [loadingRegister, setLoadingRegister] = React.useState(false);
  const [form] = Form.useForm();
  const question = Form.useWatch(form, "question");
  const handleCheckSlug = async (e) => {
    try {
      e.preventDefault();
      setSlugIsAvailable(false);
      setSlugError(false);
      if (!slug) return;
      setLoading(true);

      const { data } = await httClient({
        method: "get",
        url: `/onboard/check-slug/${slug}`,
      });
      setLoading(false);
      if (!data) {
        setSlugError(true);
      } else {
        setSlugIsAvailable(true);
      }
    } catch (error) {}
  };

  const onFinish = async (values) => {
    try {
      setLoadingRegister(true);
      const { data } = await httClient({
        method: "post",
        url: "/onboard/register",
        data: { ...values, slug, end_point: window.location.hostname },
      });
      setSlugError(false);
      setLoading(false);
      setSlugIsAvailable(false);
      setSlug("");
      setLoadingRegister(false);
      if (data) {
        if (window.location.hostname.includes("kivenda")) {
          window.open(`https://app.kivenda.com`, "target_blank");
        } else {
          window.open(`https://app.kirango.com`, "target_blank");
        }
      }
      form.resetFields();
    } catch (error) {}
    setLoadingRegister(false);
  };

  const brands = [
    {
      name: "brand1",
      imgUrl: "assets/brand1.svg",
    },
    {
      name: "brand2",
      imgUrl: "assets/brand2.svg",
    },
    {
      name: "brand3",
      imgUrl: "assets/brand3.svg",
    },
    {
      name: "brand4",
      imgUrl: "assets/brand4.svg",
    },
    {
      name: "brand5",
      imgUrl: "assets/brand5.svg",
    },
  ];
  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error("As senhas não conferem, por favor, tente novamente!")
      );
    },
  });

  return (
    <>
      <section
        id="start"
        className="section-global no-border bg-shade-blue hero"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <h1>Nosso negócio é vender o seu!</h1>
              <p className="txt-1 mt-16 me-lg-5">
                Garanta seu link personalizado agora!{" "}
                <strong>É DE GRAÇA!</strong>😎.
              </p>

              {slugIsAvailable && (
                <Form form={form} onFinish={onFinish}>
                  <div className="mt-20 me-lg-5 ">
                    <div className="d-flex  p-1 ">
                      <span
                        className="input-group-text rounded-start rounded-end-0"
                        id="inputGroupPrepend3"
                      >
                        Garanta seu Link! 🚀
                      </span>
                      <input
                        disabled
                        value={`${window.location.hostname}/${slug}`.replace(
                          "www.",
                          ""
                        )}
                        type="text"
                        className="form-control rounded-start-0 border-end-0"
                        id="validationServerUsername"
                        aria-describedby="inputGroupPrepend3"
                      />
                    </div>
                    <div className="p-1 ">
                      <Form.Item
                        name="phone"
                        className="mb-0"
                        rules={[
                          {
                            required: true,
                            message: "Por favor, insira seu nome!",
                          },
                          {
                            pattern: /^\(\d{2}\) \d{5}-\d{4}$/,
                            message: "Por favor, insira um telefone válido!",
                          },
                        ]}
                      >
                        <MaskedInput
                          className={`form-control `}
                          mask={[{ mask: "(00) 00000-0000" }]}
                          placeholder="DDD + Telefone"
                          maskOptions={{
                            dispatch: function (_, dynamicMasked) {
                              return dynamicMasked.compiledMasks[0];
                            },
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="p-1 ">
                      <Form.Item
                        name="name"
                        className="mb-0"
                        rules={[
                          {
                            required: true,
                            message: "Por favor, insira seu nome!",
                          },
                        ]}
                      >
                        <input
                          className={`form-control `}
                          placeholder="Nome de usuário"
                        />
                      </Form.Item>
                    </div>
                    <div className="p-1">
                      <Form.Item
                        name="email"
                        className="mb-0"
                        rules={[
                          {
                            required: true,
                            message: "Por favor, insira seu email!",
                          },
                          {
                            type: "email",
                            message: "Por favor, insira um email válido!",
                          },
                        ]}
                      >
                        <input
                          className={`form-control `}
                          placeholder="Seu email"
                        />
                      </Form.Item>
                    </div>
                    <div className="p-1">
                      <Form.Item
                        name="password"
                        className="mb-0"
                        rules={[
                          {
                            required: true,
                            message: "Por favor, insira sua senha!",
                          },
                          {
                            min: 6,
                            message: "A senha deve ter no mínimo 6 caracteres!",
                          },
                        ]}
                      >
                        <input
                          className={`form-control `}
                          type="password"
                          placeholder="Senha"
                        />
                      </Form.Item>
                    </div>
                    <div className="p-1">
                      <Form.Item
                        name="confirmPassword"
                        className="mb-0"
                        dependencies={["password"]}
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your password!",
                          },
                          validateConfirmPassword,
                        ]}
                      >
                        <input
                          className={`form-control `}
                          type="password"
                          placeholder="Confirme a senha"
                        />
                      </Form.Item>
                    </div>
                    <div className="p-1">
                      <Form.Item
                        label="Qual seu interesse?"
                        name="question"
                        className="mb-0"
                        rules={[
                          {
                            required: true,
                            message: "Por favor, responda a pergunta!",
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Space direction="horizontal">
                            <Card
                              style={{
                                width: 150,
                                height: 150,
                                textAlign: "center",
                                border:
                                  question === "whatsapp"
                                    ? "2px solid #1890ff"
                                    : "1px solid #d9d9d9",
                              }}
                            >
                              <Radio
                                value="whatsapp"
                                style={{ display: "block" }}
                              >
                                <WhatsAppOutlined
                                  style={{ fontSize: "24px", color: "#25D366" }}
                                />
                                <p style={{ marginTop: "10px" }}>
                                  Link para WhatsApp
                                </p>
                              </Radio>
                            </Card>
                            <Card
                              style={{
                                width: 150,
                                height: 150,
                                textAlign: "center",
                                border:
                                  question === "store"
                                    ? "2px solid #1890ff"
                                    : "1px solid #d9d9d9",
                              }}
                            >
                              <Radio value="store" style={{ display: "block" }}>
                                <ShopOutlined
                                  style={{ fontSize: "24px", color: "#1890ff" }}
                                />
                                <p style={{ marginTop: "10px" }}>
                                  Ter uma loja digital
                                </p>
                              </Radio>
                            </Card>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    <div className="p-1 d-flex justify-content-center">
                      <button className="btn btn-primary btn-lg loading">
                        {loadingRegister && (
                          <>
                            <span
                              className="spinner-border spinner-border-sm mx-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </>
                        )}
                        Cadastrar
                      </button>
                    </div>
                  </div>
                </Form>
              )}
              {slugIsAvailable && <ConfettiExplosion />}
              {!slugIsAvailable && (
                <form className="mt-20 me-lg-5 ">
                  <div className="cta-form d-flex align-items-center justify-content-between">
                    <div className="cta-input ">
                      <input
                        className={`form-control ${
                          slugIsAvailable
                            ? "is-valid"
                            : slugError && "is-invalid"
                        }`}
                        id="exampleFormControlInput1"
                        placeholder="Seu negócio"
                        value={slug}
                        onChange={(e) => setSlug(Slugify(e.target.value))}
                      />
                    </div>

                    <div className="cta-btn ">
                      <button
                        disabled={loading}
                        type="submit"
                        onClick={handleCheckSlug}
                        className="btn btn-primary btn-lg loading"
                      >
                        {loading && (
                          <>
                            <span
                              className="spinner-border spinner-border-sm mx-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </>
                        )}
                        Iniciar vendas!
                      </button>
                    </div>
                  </div>
                </form>
              )}
              <p className="mt-16 mb-0 txt-4">
                ou Veja nossas opções para outro nível 😊{" "}
                <Link className="white-link" onClick={() => anchor("#pricing")}>
                  valores e planos
                </Link>
              </p>
            </div>
            <div className="col-lg-6 align-self-center d-flex justify-content-center text-center">
              <div style={{ maxWidth: "450px" }}>
                <img
                  src={"/kirango.png"}
                  className="img-fluid mt-5 mt-lg-0"
                  alt="hero"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <BrandSection
        brands={brands}
        src={"landing1"}
        bg="bg-shade-blue"
        light
        bordered
      />
    </>
  );
};

const Services = () => {
  const services = [
    {
      name: "Catálogos Digital",
      info: "Crie catálogos digitais exclusivos com o nome do seu negócio. Facilite o acesso dos seus clientes com links personalizados",
      icoUrl: "assets/service_ico1.svg",
    },
    {
      name: "Gestão Simplificada",
      info: "Utilize nosso painel intuitivo para gerenciar pedidos, mesas e catálogos de forma eficiente.",
      icoUrl: "assets/service_ico2.svg",
    },
    {
      name: "Gestão de Pedidos",
      info: "Organize seus pedidos com nosso sistema de Kanban, garantindo uma operação mais fluida e organizada.",
      icoUrl: "assets/service_ico3.svg",
    },
    {
      name: "Relatórios Detalhados",
      info: "Acompanhe visitas, comentários e quantidades de pedidos com nossas telas de relatórios detalhados.",
      icoUrl: "assets/service_ico6.svg",
    },
  ];
  return (
    <>
      <section id="services" className="section-global">
        <div className="container">
          <div className="row mb-40">
            <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
              <div className="section-tag mb-8">Serviços</div>
              <h2>Novidades da plataforma</h2>
            </div>
          </div>
          <div className="row">
            {services.map((service, i) => (
              <div
                className="col-lg-4 col-xl-3 col-md-6 col-sm-6 gx-5 gy-4"
                key={i}
              >
                <ServiceCards data={service} src="landing1" />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

const About = () => {
  const timeline = [
    {
      title: "Crie o Site",
      text: "Crie catálogos digitais exclusivos com o nome do seu negócio",
    },
    {
      title: "Vincule seu produto",
      text: "Escolha quais produtos deseja vincular ao catálogos digital",
    },
    {
      title: "Inicie suas vendas",
      text: "Compartilhe seu link personalizado e comece a vender!",
    },
  ];
  return (
    <>
      <section id="orders" className="section-global bg-shade-1">
        <div className="container">
          <div className="row gy-5">
            <div className="col-lg-6 text-center align-self-center">
              <img
                src={require("./assets/about.png")}
                className="img-fluid about-banner-image"
                alt="how we work"
              />
            </div>
            <div className="col-lg-6">
              <div className="section-tag mb-8">Pedidos</div>
              <h2 className="mb-16">
                Crie, gerencie e acompanhe seus pedidos de forma simples
              </h2>
              <p className="txt-1 mb-16">
                Reduza erros e aumente a produtividade com nosso sistema de
                gestão de pedidos e mesas.
              </p>
              <div className="time-line">
                {timeline.map((e, i) => (
                  <div className="content" key={i}>
                    <div className="index">{i + 1}</div>
                    <h3 className="mb-8 mb-0">{e.title}</h3>
                    <p className="txt-2 mb-0">{e.text}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Counters = () => {
  const counter_data = [
    {
      count: "5K+",
      text: "Delivered projects",
    },
    {
      count: "43%",
      text: "Market share",
    },
    {
      count: "75",
      text: "Awards won",
    },
    {
      count: "3m+",
      text: "Users worldwide",
    },
  ];
  return (
    <>
      <section className="counter-section dark">
        <div className="container">
          <div className="row">
            {counter_data.map((e, i) => (
              <div className="col-lg-3 col-md-4 col-6 gy-4 text-center" key={i}>
                <h2 className="display-1">{e.count}</h2>
                <p className="txt-1">{e.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

// REMOVIDO DO LAYOUT
// "Check-in de Mesas",
// "Garçom Digital",
export const pricing_data = [
  {
    title: "Grátis",
    text: "Para quem está começando",
    price: "R$ 0",
    period: "mês",
    included: ["1 Catálogo Digitais", "Até 15 produtos", "Gestão de Pedidos"],
    not_included: [
      "Caixa PDV",
      "Programa de Fidelidade",
      "Integrações com WhatsApp",
      "Gestão de Mesas",
      "Anúncios ao vivo",
      "Cupom de desconto",
    ],
  },
  {
    title: "Basic",
    text: "Lojinhas e pequenos negócios",
    price: "R$ 69",
    period: "mês",
    included: [
      "1 Catálogo Digitais",
      "Até 30 produtos",
      "Gestão de Pedidos",
      "Caixa PDV",
    ],
    not_included: [
      "Programa de Fidelidade",
      "Integrações com WhatsApp",
      "Gestão de Mesas",
      "Anúncios ao vivo",
      "Cupom de desconto",
    ],
  },
  {
    title: "Pro",
    text: "Para negócios em crescimento",
    price: "R$ 99",
    period: "mês",
    included: [
      "2 Catálogos Digitais",
      "Até 60 produtos",
      "Gestão de Pedidos",
      "Caixa PDV",
      "Programa de Fidelidade",
    ],
    not_included: [
      "Integrações com WhatsApp",
      "Gestão de Mesas",
      "Anúncios ao vivo",
      "Cupom de desconto",
    ],
    featured: true,
  },
  {
    title: "Enterprise",
    text: "Para grandes negócios",
    price: "R$ 149",
    period: "mês",
    included: [
      "4 Catálogos Digitais",
      "Até 120 produtos",
      "Gestão de Pedidos",
      "Caixa PDV",
      "Programa de Fidelidade",
      "Integrações com WhatsApp",
      "Gestão de Mesas",
      "Anúncios ao vivo",
      "Cupom de desconto",
    ],
  },
];
export const Pricing = ({ description }) => {
  return (
    <>
      <section id="pricing" className="section-global">
        <div className="container">
          <div className="row mb-40">
            <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
              <div className="section-tag mb-8">Preços</div>
              {description ? (
                <h4>{description}</h4>
              ) : (
                <h4>
                  Experimente KiVenda gratuitamente e veja como podemos
                  transformar a gestão do seu estabelecimento.
                </h4>
              )}
            </div>
          </div>
          <div className="row">
            {pricing_data.map((e, i) => (
              <div className="col-lg-3 col-md-6 gy-4" key={i}>
                <PricingCard data={e} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

const Blog = () => {
  const blog_data = [
    {
      category: "Organize",
      title: "Como organizar seu catálogos digital para melhorar as vendas",
      date: "09 de Setembro de 2023",
      imgUrl: "assets/blog_thumb1.jpg",
      // link: "/blog-article",
    },
    {
      category: "Anúncios",
      title: "Aprenda a fazer anúncios ao vivo para atrair mais clientes",
      date: "09 de Setembro de 2023",
      imgUrl: "assets/blog_thumb2.jpg",
      // link: "/blog-article",
    },
    {
      category: "Gestão",
      title: "Como gerenciar pedidos e mesas de forma eficiente",
      date: "09 de Setembro de 2023",
      imgUrl: "assets/blog_thumb3.jpg",
      // link: "/blog-article",
    },
  ];

  return (
    <>
      <section id="blog" className="section-global bg-shade-green">
        <div className="container">
          <div className="row mb-40 justify-content-between gy-4">
            <div className="col-xl-5 col-lg-5">
              <div className="section-tag mb-8">Blog</div>
              <h2>Veja as atualizações de nossa equipe</h2>
            </div>
            <div className="col d-flex align-self-center">
              <Link to="/blog" className="btn btn-outline btn-arrow ms-lg-auto">
                Nosso blog
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z"
                    fill="black"
                  />
                </svg>
              </Link>
            </div>
          </div>
          <div className="row">
            <BlogCards data={blog_data} src="landing1" />
          </div>
        </div>
      </section>
    </>
  );
};

const Contact = () => {
  const contact_data = {
    title: "Alguma sugestão?",
    title_highlight: "Nos mande uma mensagem!",
    text: "Nossa equipe está pronta para te ajudar a transformar a gestão do seu negócio. Entre em contato conosco e veja como podemos te ajudar.",
    link: "/contact",
  };

  return (
    <>
      <ContactSection contact_data={contact_data} />
    </>
  );
};

export default Landing1;
